import React from 'react'

import data from '../data/projects'
import ProjectList from '../components/projects/projectList'
import PartnersList from '../components/partners/partnersList'

const teste = () => {

 return (
  <div>

   <ProjectList projects={data} />

   <PartnersList />


  </div>
 )
}

export default teste;
